@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  @media print {
    display: block;
  }
}
.bubbles {
  position: relative;
  display: flex;
  justify-content: space-around;
  gap: 32px;
  @include media-down(xxl) {
    flex-direction: column;
    min-height: 632px;
  }
  @media print {
    display: block;
    margin: auto;
    text-align: center;
    margin-left: 60px;
  }
}
.decorator {
  position: absolute;
  top: 64px;
  width: 584px;
  height: 364px;
  @include media-down(xxl) {
    transform: rotate(90deg);
    top: 30%;
    width: 632px;
    height: 414px;
  }
  &_count-1 {
    @include media-down(xxl) {
      transform: rotate(90deg) translateY(-14px);
      top: 20%;
    }
  }
  &_count-2 {
    @include media-down(xxl) {
      top: 12%;
    }
  }
}
.bubble {
  &_1 {
    margin-top: 50px;
    @include media-down(xxl) {
      margin-top: 0;
      margin-right: 200px;
    }
    @include media-down(lg) {
      margin-right: 80px;
    }
    @include media-down(sm) {
      margin: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &_2 {
    @include media-down(xxl) {
      margin-left: 200px;
    }
    @include media-down(lg) {
      margin-left: 80px;
    }
    @include media-down(sm) {
      margin: 0;
    }
  }
  &_3 {
    margin-top: 100px;
    @include media-down(xxl) {
      margin-top: 0;
      margin-right: 240px;
    }
    @include media-down(lg) {
      margin-right: 120px;
    }
    @include media-down(sm) {
      margin: 0;
    }
  }
  @media print {
    &_1,
    &_2,
    &_3 {
      page-break-inside: avoid;
      // margin-top: 16px !important;
      // margin-bottom: 16px !important;
    }
  }
}
