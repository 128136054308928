@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  text-align: center;
  background-color: $color-light;
}

.color {
  &_primary {
    border-color: $color-primary;
    color: $color-primary;
  }
  &_variant1 {
    border-color: $color-variant-1;
    color: $color-variant-1;
  }
  &_variant2 {
    border-color: $color-variant-1-dark;
    color: $color-variant-1-dark;
  }
  &_variant3 {
    border-color: $color-variant-2;
    color: $color-variant-2;
  }
  &_variant4 {
    border-color: $color-variant-3;
    color: $color-variant-3;
  }
}

.content {
  padding: 58px 32px;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 20px;
}
