@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-items: center;
}
.content {
  justify-self: stretch;
  grid-column: 3/11;
  margin-bottom: 40px;
  @include media-down(xxl) {
    grid-column: 2/12;
  }
  @include media-down(xl) {
    grid-column: 1/13;
  }
  @include media-down(md) {
    margin-bottom: 20px;
  }
  @include media-down(sm) {
    margin-bottom: 0px;
  }
}
.button {
  grid-row: 2;
  grid-column: 1/13;
}
