@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
  @media print {
    display: block;
  }
  @include media-down(xxxl) {
    grid-template-columns: 1fr 1fr 1fr;
    width: fit-content;
  }
  @include media-down(xxl) {
    grid-template-columns: 1fr 1fr;
    width: fit-content;
  }
  @include media-down(lg) {
    grid-template-columns: 1fr;
  }
}
