@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

$imageHeight: 180px;

@mixin colored-style($label, $color) {
  .container {
    border: 3px solid $color-variant-6-light;
    border-radius: 30px;
    height: 390px;
    position: relative;
    width: 321px;
    @media print {
      page-break-inside: avoid;
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
    &_#{$label} {
      color: $color;
      &::after {
        content: "";
        position: absolute;
        top: calc($imageHeight - 4px);
        width: 100px;
        height: 10px;
        background-color: $color;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 20px;
      }
    }
  }

  .image {
    height: $imageHeight;
    max-height: $imageHeight;
    position: relative;
    &_#{$label} {
      border-bottom: 2px solid $color;
    }
    img {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
  }
}

.title {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 12px;
  min-height: 80px;
  font-size: 1.125rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

@include colored-style(primary, $color-primary);
@include colored-style(variant1, $color-variant-1);
@include colored-style(variant2, $color-variant-1-dark);
@include colored-style(variant3, $color-variant-2);
@include colored-style(variant4, $color-variant-3);
