@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  @media print {
    display: block;
    text-align: center;
  }
}
.mainKeyNumberContainer {
  position: relative;
}
.decorator {
  opacity: 0.1;
}
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  @include media-down(md) {
    width: 100%;
  }
}
.mainKeyNumber {
  font-size: 4.25rem;
  @include media-down(sm) {
    font-size: 3.5rem;
  }
  @media (max-width: 450px) {
    font-size: 3.125rem;
  }
  @media (max-width: 380px) {
    font-size: 2.75rem;
  }
  @include media-down(xs) {
    font-size: 2.25rem;
  }
}
.mainKeyNumberDescription {
  max-width: 320px;
  align-self: center;
  @media (max-width: 450px) {
    font-size: 1rem;
  }
  @include media-down(xs) {
    max-width: 250px;
  }
}
.altKeyNumbersContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 50px;
  @include media-down(md) {
    row-gap: 30px;
  }
  @media print {
    display: block;
    margin: auto;
  }
}
.keyNumber {
  width: 220px;
  display: flex;
  flex-direction: column;
  border-right: 4px dotted $color-primary;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 5px;
  &:last-of-type,
  &:nth-of-type(4n) {
    border: none;
  }
  @include media-down(xxxl) {
    &:nth-of-type(4n) {
      border-right: 4px dotted $color-primary;
    }
    &:nth-of-type(3n) {
      border-right: none;
    }
  }
  @include media-down(xxl) {
    &:nth-of-type(3n) {
      border-right: 4px dotted $color-primary;
    }
    &:nth-of-type(2n) {
      border-right: none;
    }
  }
  @include media-down(md) {
    padding-bottom: 30px;
    &:nth-of-type(n) {
      border-right: none;
      border-bottom: 4px dotted $color-primary;
    }
    &:last-of-type {
      border: none;
    }
  }
  @media print {
    display: block;
    margin: 32px auto;
  }
}
.keyNumberValue {
  margin-bottom: 15px;
  font-size: 3.125rem;
  @media print {
    display: block;
  }
}
