@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.wrap {
  position: relative;
  height: 100%;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-down(xxl) {
    flex-direction: column;
    gap: 80px;
  }
  @include media-down(md) {
    flex-direction: column;
    gap: 40px;
  }
  @media print {
    display: block;
    text-align: center;
  }
}
.map {
  @include grid(5);
}
.contentContainer {
  @include grid(6);
  position: relative;
  @include media-down(xxl) {
    width: 100%;
  }
  @media print {
    text-align: start;
    margin-top: 80px;
    page-break-inside: avoid;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 50vw;
    background-color: $color-variant-6-light;
    @include media-down(xxl) {
      display: none;
    }
  }
}
.content {
  padding: 50px;
  height: 100%;
  background-color: $color-variant-6-light;
  border-radius: 30px 0px 0px 30px;
  @include media-down(xxl) {
    border-radius: 30px;
  }
  @include media-down(sm) {
    padding: 32px;
  }
}
.title {
  margin-top: 0;
}
.description {
  margin-bottom: 30px;
}

@mixin fill-colors($lvl1, $lvl2, $lvl3, $lvl4, $hover) {
  --map-color-lvl-1: #{$lvl1};
  --map-color-lvl-2: #{$lvl2};
  --map-color-lvl-3: #{$lvl3};
  --map-color-lvl-4: #{$lvl4};
  --map-color-hover: #{$hover};
}
.color-bleu {
  @include fill-colors(
    $color-variant-4-light,
    $color-variant-4,
    $color-variant-4-dark,
    $color-variant-4-darkest,
    $color-variant-5
  );
}

.color-turquoise {
  @include fill-colors(
    $color-variant-5-light,
    $color-variant-5,
    $color-variant-5-dark,
    $color-variant-5-darkest,
    $color-variant-1-dark
  );
}

.color-vert {
  @include fill-colors(
    $color-variant-3-light,
    $color-variant-3,
    $color-variant-3-dark,
    $color-variant-3-darkest,
    $color-variant-3
  );
}

.color-rouge {
  @include fill-colors(
    $color-variant-2-light,
    $color-variant-2,
    $color-variant-2-dark,
    $color-variant-2-darkest,
    $color-variant-1
  );
}

.legend {
  margin-bottom: 32px;
}
.legendItem {
  display: flex;
  align-items: center;
  gap: 20px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.legendColor {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  @include media-down(sm) {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }
  &_lvl1 {
    background-color: var(--map-color-lvl-1);
  }
  &_lvl2 {
    background-color: var(--map-color-lvl-2);
  }
  &_lvl3 {
    background-color: var(--map-color-lvl-3);
  }
  &_lvl4 {
    background-color: var(--map-color-lvl-4);
  }
}
.legendLabel {
  display: block;
  flex-shrink: 1;
}
