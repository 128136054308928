@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  @include set-grid();
  justify-items: center;
}

.imageWrap {
  grid-column: 2/6;
  align-self: center;
  @include media-down(xxl) {
    grid-column: 2/12;
  }
  @include media-down(xl) {
    grid-column: 1/13;
  }
  &_reverse {
    grid-column: 8/12;
    @include media-down(xxl) {
      grid-column: 2/12;
    }
    @include media-down(xl) {
      grid-column: 1/13;
    }
  }
}

.separator {
  grid-column: 6/7;
  position: relative;

  $border: 4px dotted #15b8d6;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    height: 100%;
    border-right: $border;
  }

  &_reverse {
    grid-column: 7/8;
    grid-row: 1;
  }
  @include media-down(xxl) {
    grid-row: 2;
    grid-column: 2/12;
    width: 100%;
    &::after {
      left: 0;
      height: unset;
      width: 100%;
      border-right: none;
      border-top: $border;
    }
  }
  @include media-down(xl) {
    grid-row: 2;
    grid-column: 1/13;
  }
}
.content {
  grid-column: 7/12;
  &_reverse {
    grid-column: 2/7;
    grid-row: 1;
  }
  @include media-down(xxl) {
    grid-row: 3;
    grid-column: 2/12;
  }
  @include media-down(xl) {
    grid-column: 1/13;
  }
}
.body {
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
}

.button {
  width: fit-content;
  @include media-down(xxl) {
    margin: auto;
  }
}
