@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

$border-style: 4px dotted $color-variant-6-light;

.container {
  @include set-grid();
  row-gap: 0;
  @media print {
    display: block;
  }
}

.logo {
  position: relative;
  grid-column: 2/5;
  min-height: 180px;
  @include media-down(xl) {
    grid-column: 1/13;
    grid-row: 1;
  }
}
.dates {
  grid-column: 2/5;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  @include media-down(xl) {
    grid-column: 1/13;
    grid-row: 4;
    padding-top: 32px;
    border-top: $border-style;
  }
  @media print {
    display: block;
  }
}
.date {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
  }
}
.dateLabel {
  margin-bottom: 20px;
  @media print {
    display: block;
  }
}
.divider {
  grid-column: 5/6;
  grid-row: 1/3;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    border-right: $border-style;
    left: 50%;
  }
  @include media-down(xl) {
    display: none;
  }
}
.serviceInfo {
  grid-column: 6/12;
  display: flex;
  flex-direction: column;
  @include media-down(xl) {
    grid-column: 1/13;
    grid-row: 2;
  }
  @media print {
    display: block;
    page-break-inside: avoid;
  }
}
.serviceDescription {
  padding: 32px 0;
  @media print {
    display: block;
  }
}
.related {
  grid-column: 6/12;
  @include media-down(xl) {
    grid-column: 1/13;
    grid-row: 3;
  }
}
.users {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  border-top: $border-style;
  border-bottom: $border-style;
  @media print {
    display: block;
    page-break-inside: avoid;
  }
}
.userTitle {
  margin-bottom: 32px;
  @media print {
    display: block;
  }
}
.userList {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @include media-down(sm) {
    justify-content: center;
  }
}
.otherServices {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  @media print {
    display: block;
  }
}
.otherServicesTitle {
  margin-bottom: 32px;
  @media print {
    display: block;
  }
}
.otherService {
  display: grid;
  column-gap: 32px;
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
  @include media-down(sm) {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
  @media print {
    page-break-inside: avoid;
  }
  @media print {
    display: block;
  }
}
.otherServiceImage {
  position: relative;
  grid-column: 1;
  grid-row: 1/3;
  @include media-down(sm) {
    grid-column: 1;
    grid-row: 1;
    width: auto;
  }
  @media print {
    margin-bottom: 16px;
  }
}
.otherServiceTitle {
  grid-column: 2;
  grid-row: 1;
  align-self: flex-end;
  @include media-down(sm) {
    grid-column: 1;
    grid-row: 2;
    margin-top: 8px;
  }
  @media print {
    display: block;
  }
}
.otherServiceDescription {
  grid-column: 2;
  grid-row: 2;
  @include media-down(sm) {
    grid-column: 1;
    grid-row: 3;
  }
}
