@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 140px;
  @include media-down(xl) {
    margin-top: 120px;
  }
  @include media-down(md) {
    margin-top: 100px;
  }
  @include media-down(sm) {
    margin-top: 90px;
  }
  @media print {
    display: block;
    page-break-inside: avoid;
    position: relative;
  }
}

.decorator {
  position: absolute;
  top: -40px;
  @media screen and (max-width: 1455px) {
    width: 880px;
  }
  @include media-down(xl) {
    width: 608px;
  }
  @media screen and (max-width: 630px) {
    top: -30px;
    width: 580px;
  }
  @include media-down(sm) {
    width: 520px;
  }
  @include media-down(sm) {
    width: 500px;
  }
  @media screen and (max-width: 510px) {
    top: -20px;
    width: 475px;
  }
  @media screen and (max-width: 480px) {
    top: -18px;
    width: 380px;
  }
  @media screen and (max-width: 390px) {
  }
  @media print {
    transform: scale(0.6);
    top: -36px;
    left: -20px;
  }
}

.videoContainer {
  z-index: 1;
  width: 100%;
  height: 510px;
  max-width: 906px;
  @media screen and (max-width: 1455px) {
    height: 450px;
    max-width: 796px;
  }
  @include media-down(xl) {
    height: 350px;
    max-width: 619px;
  }
  @include media-down(xl) {
    height: 300px;
    max-width: 531px;
  }
  @include media-down(sm) {
    height: 250px;
    max-width: 442px;
  }
  @media screen and (max-width: 480px) {
    height: 200px;
    max-width: 354px;
  }
  iframe {
    border: none;
  }
  @media print {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.description {
  text-align: center;
  margin-top: 70px;
  max-width: 587px;
  @media print {
    margin-top: 120px;
  }
}

.videoLink {
  display: none;
  text-align: center;
  @media print {
    display: block;
    z-index: 10;
    & a {
      color: $color-light;
    }
  }
}
.iframe {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
  @media print {
    display: none;
  }
}
