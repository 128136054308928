@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.title {
  z-index: 100;
}
.baseContainer {
  position: relative;
}

.container {
  position: relative;
  z-index: 20;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: $grid-gutter-width;
}

.content {
  grid-column: 2 / 12;
  @include media-down(md) {
    grid-column: 1/13;
  }
}

.picto {
  z-index: 10;
  position: absolute;
  top: 30px;
  right: 30px;
  @include media-down(md) {
    width: 175px;
  }
  @include media-down(sm) {
    right: unset;
    right: -32px;
  }
}
