@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 64px;
  @media print {
    display: block;
  }
}
.poles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 64px;
  column-gap: 56px;
  @media print {
    display: block;
  }
}
.pole {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 20px;
  @media print {
    display: block;
    margin: auto;
    text-align: center;
    page-break-inside: avoid;
    &:not(:last-of-type) {
      margin-bottom: 64px;
    }
  }
}
.image {
  border: 1px solid $color-secondary !important;
  display: flex;
  @media print {
    margin-bottom: 32px;
  }
}
.divider {
  width: 1px;
  height: 30px;

  background-color: $color-secondary;
  @media print {
    margin: 32px auto;
  }
}
