@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
}

.wrapper {
  grid-column: 3/11;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include media-down(xxl) {
    grid-column: 2/12;
  }
  @include media-down(xl) {
    grid-column: 1/13;
  }
  @media print {
    display: block;
  }
}

.bulletPoint {
  display: flex;
  align-items: center;
  column-gap: 30px;
  @media print {
    margin-bottom: 12px;
  }
}

.iconWrapper {
  display: flex;
  align-self: flex-start;
  margin-top: 2px;
}
.icon {
  font-size: 20px;
  color: $color-primary;
  flex-grow: 1;
}
.printWrap {
  @media print {
    page-break-inside: avoid;
  }
}
