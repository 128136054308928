@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.edito {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: $grid-gutter-width;
  &:not(:last-of-type) {
    margin-bottom: 80px;
  }
  @include media-down(xl) {
    //  TODO bad accessibility practice to change the DOM content order with CSS
  }
  @media print {
    display: block;
  }
}
.content {
  position: relative;
  grid-column: 2/8;
  margin-top: 0;
  margin-bottom: 0;
  @include media-down(xxl) {
    grid-column: 1/7;
  }
  @include media-down(xl) {
    grid-column: 1/13;
  }
  &_1 {
    @include media-up(xl) {
      grid-row: 1;
    }
  }
}
.iconQuotes {
  position: absolute;
  font-size: 120px;
  color: $color-variant-6-light;
  z-index: 10;
  left: -40px;
  top: -32px;
  @include media-down(md) {
    font-size: 80px;
    left: -8px;
    top: -20px;
  }
  @media print {
    left: -35px;
    top: -12px;
  }
}
.body {
  position: relative;
  z-index: 50;
}
.separator {
  grid-column: 8/9;
  height: inherit;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    border-left: 2px dotted $color-primary;
    left: 50%;
  }
  @include media-down(xxl) {
    grid-column: 7/8;
  }
  @include media-down(xl) {
    display: none;
  }
}
.authors {
  grid-column: 9/13;

  @include media-down(xxl) {
    grid-column: 8/13;
  }
  @include media-down(xl) {
    grid-row: 1;
    grid-column: 1/13;
    margin-bottom: 64px;
  }
}

.edito_odd {
  .authors {
    grid-column: 1/5;
    grid-row: 1;
    @include media-down(xxl) {
      grid-column: 1/6;
    }

    @include media-down(xl) {
      grid-row: 1;
      grid-column: 1/13;
      margin-bottom: 64px;
    }
  }
  .separator {
    grid-column: 5/6;
    grid-row: 1;
    @include media-down(xxl) {
      grid-column: 6/7;
    }
  }
  .content {
    grid-column: 6/12;

    @include media-down(xxl) {
      grid-column: 7/13;
    }
    @include media-down(xl) {
      grid-column: 1/13;
    }
  }
}
