@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.sectionBase {
  @include media-down(xl) {
    margin-top: -50px;
  }
  @include media-down(md) {
    margin-top: 50px;
  }
  @include media-down(sm) {
    margin-top: 40px;
  }
  @media print {
    margin-bottom: 0;
  }
}

.gridContainer {
  @include set-grid();
  justify-items: center;
  align-items: center;
  @include media-down(xl) {
    display: block;
  }
}
.contentContainer {
  grid-column: 1/6;
  grid-row: 1;
  @include media-down(xxl) {
    grid-column: 1/7;
  }
}
.headlineContainer {
  display: flex;
  flex-direction: column;
  @include media-down(xl) {
    margin-top: 60px;
  }
}
.imageContainer {
  grid-column: 7/13;
  position: relative;

  width: 438px;
  height: 396px;
  @include media-down(xxxl) {
    width: 372px;
    height: 336px;
  }
  @include media-down(xxl) {
    width: 315px;
    height: 285px;
    grid-column: 8/13;
  }
  @include media-down(xl) {
    width: 307px;
    height: 277px;
    margin: auto;
    margin-bottom: 100px;
  }
  @include media-down(md) {
    width: 285px;
    height: 257px;
  }
  @media screen and (max-width: 450px) {
    width: 219px;
    height: 198px;
  }
  @media print {
    margin-top: 64px;
    page-break-inside: avoid;
  }
}
.subtitle {
  margin-bottom: 8px;
}
.headline {
  margin-bottom: 30px;
}
.title {
  margin-bottom: 55px;
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    bottom: -16px;
    left: 0;
    height: 10px;
    width: 100px;
    background-color: $color-variant-6-light;
    border-radius: 20px;
  }
}
.image {
  position: absolute;
  display: flex;
  border: 3px solid $color-light;
  filter: drop-shadow(2px 2px 6px rgba(82, 100, 109, 0.15));
  border-radius: 100%;
  overflow: hidden;
  &_primary_1 {
    width: 98%;
    height: 108.5%;
    top: 6%;
    left: 12%;
  }
  &_secondary_1 {
    width: 71%;
    height: 78%;
    top: -18%;
    left: 43%;
  }
  &_secondary_2 {
    width: 68.5%;
    height: 76.8%;
    top: 47%;
    left: -19%;
  }
  &_secondary_3 {
    width: 98%;
    height: 108.5%;
    top: 6%;
    left: 12%;
  }
  &_tertiary_1 {
    width: 52.5%;
    height: 58%;
    top: -20%;
    left: 1%;
  }
  &_tertiary_2 {
    width: 60.5%;
    height: 67%;
    top: 18%;
    right: -14%;
  }
  &_tertiary_3 {
    width: 68.5%;
    height: 75.7%;
    top: 48%;
    left: -20%;
  }
}
.imageCustom {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.cta {
  margin-top: 48px;
  margin-bottom: 24px;
}
.button {
  margin-top: 48px;
  width: fit-content;
  margin-bottom: 24px;
}
