@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  margin-top: -30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  column-gap: 100px;
  row-gap: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
  position: relative;
  text-align: center;
  &::after {
    content: "";
    position: absolute;
    top: -80px;
    width: 100px;
    height: 10px;
    background-color: $color-variant-6-light;
    border-radius: 20px;
    transform: rotate(90deg);
    @include media-down(xl) {
      top: -70px;
      width: 70px;
    }
    @include media-down(md) {
      top: -50px;
      width: 50px;
    }
  }
  @include media-down(xl) {
    margin-top: 130px;
  }
  @include media-down(md) {
    margin-top: 100px;
  }
}
