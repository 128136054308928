@use "sass:math";

@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

$containerMargin: 100px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 450px) {
    margin-right: 30px;
    margin-left: 30px;
  }
  @media screen and (max-width: 380px) {
    margin-right: 20px;
    margin-left: 20px;
  }
  &_image-medium {
    gap: 10px;
  }
  &_image-large {
    gap: 32px;
  }
}
.labelWrap {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: $containerMargin;
  overflow-wrap: break-word;
}

.label {
  hyphens: auto;
}
