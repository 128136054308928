@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
  @include media-down(xl) {
    height: 80px;
    width: 1000px;
    overflow-x: hidden;
  }
  @include media-down(md) {
    height: 56px;
    width: 770px;
  }
  @media print {
    display: block;
    page-break-inside: avoid;
  }
}

.option1 {
  height: 120px;
}
.option2 {
  height: 60px;
}
