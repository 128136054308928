@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.author {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  justify-content: space-between;
  align-items: center;
  @include media-down(sm) {
    gap: 12px;
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}
.imageWrap {
  position: relative;
  justify-self: end;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  @include media-down(xxl) {
    width: 148px;
    height: 148px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      65.15deg,
      $color-secondary 9.07%,
      $color-primary 44.99%,
      $color-primary 89.89%
    );
  }
}
.image {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}
.info {
  display: flex;
  flex-direction: column;
}
.name {
  margin-bottom: 16px;
}

@mixin reverse() {
  text-align: end;
  .info {
    grid-column: 1;
    grid-row: 1;
  }
  .imageWrap {
    grid-column: 2;

    justify-self: start;
  }
}
.author_reverse {
  @include reverse();
}
.author:nth-of-type(even) {
  @include media-down(xl) {
    @include reverse();
  }
}
