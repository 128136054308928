@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  row-gap: 120px;
  text-align: center;
  margin-top: 112px;
  margin-bottom: 112px;
  @include media-down(md) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  @include media-down(sm) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  gap: 30px;
  @include media-down(md) {
    width: 100%;
  }
  &_alt {
    @include media-down(md) {
      flex-direction: column;
      gap: 0px;
    }
  }
}

.decorator {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: scale(1.2);
  @include media-down(md) {
    transform: scale(1.1);
    max-width: 97%;
    &_alt {
      display: none;
    }
  }
}

.imageWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

.imageWrapper_alt {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
}

.description {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 850px;
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
