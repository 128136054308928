@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  background-color: $color-secondary;
  border-radius: 100%;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  @media screen and (max-width: 450px) {
    height: 189px;
    width: 189px;
  }
  @media screen and (max-width: 450px) {
    height: 179px;
    width: 179px;
  }
  @media screen and (max-width: 380px) {
    height: 169px;
    width: 169px;
  }
  @include media-down(xs) {
    height: 159px;
    width: 159px;
  }
}

.keyNumber {
  font-size: 2.5rem;
  @include media-down(md) {
    font-size: 2.25rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 1.75rem;
  }
}

.description {
  padding-left: 17px;
  padding-right: 17px;
  @include media-down(md) {
    font-size: 0.875rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 0.75rem;
  }
}
