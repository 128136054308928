@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  &_alt {
    @include media-down(md) {
      padding-bottom: 100px;
    }
  }
  @media print {
    display: block;
    page-break-inside: avoid;
  }
}

.backgroundContainer {
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column-reverse;
  align-items: center;
  @include media-up(md) {
    margin-top: 40px;
  }
}

.description {
  margin-bottom: 120px;
  @include media-up(md) {
    position: absolute;
    top: 200px;
    right: 50%;
    transform: translate(50%, -25%);
    max-width: 300px;
  }
  @include media-down(md) {
    margin-bottom: 100px;
  }
  @include media-down(sm) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.keyContainers {
  position: absolute;
  top: 0;
  @include media-down(md) {
    top: initial;
    bottom: -30px;
  }
  @include media-down(xs) {
    bottom: -50px;
  }
}

.keyNumbers {
  position: relative;
  div {
    position: absolute;
  }
  div:nth-child(1) {
    top: -45px;
    left: 50%;
    transform: translateX(-165%);
    @include media-down(md) {
      top: initial;
      bottom: 300px;
      transform: translateX(-100%);
    }
  }
  div:nth-child(2) {
    top: -15px;
    left: 50%;
    transform: translateX(60%);
    @include media-down(md) {
      top: initial;
      bottom: 180px;
      transform: translateX(0%);
    }
  }
  div:nth-child(3) {
    top: 300px;
    right: 50%;
    transform: translateX(50%);
    @include media-down(md) {
      top: initial;
      bottom: 60px;
      transform: translateX(0%);
    }
  }
  &_alt {
    div:nth-child(3) {
      top: 320px;
      left: 50%;
      @include media-down(md) {
        top: initial;
        bottom: 60px;
        transform: translateX(-100%);
      }
    }
    div:nth-child(4) {
      top: 280px;
      left: 50%;
      transform: translateX(-150%);
      @include media-down(md) {
        top: initial;
        bottom: -50px;
        transform: translateX(0%);
      }
    }
  }
}

.buttonContainer {
  margin-top: 160px;
  display: flex;
  justify-content: center;
  text-align: center;
  @include media-down(md) {
    margin-top: 60px;
  }
  &_alt {
    @include media-down(md) {
      margin-top: 140px;
    }
  }
}
