@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

$transition: all 0.5s ease-in-out;

.container {
  border-bottom: 1px solid $color-light;
  &:last-of-type {
    border-bottom: none;
  }
  padding-bottom: 30px;
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  transition: $transition;
  color: $color-light;
  // &_display {
  //   color: $color-variant-3-light;
  // }
}

.question {
  max-width: 80%;
  @include media-down(md) {
    max-width: 75%;
  }
  @media print {
    max-width: unset;
  }
}

.answer {
  max-width: 85%;
  max-height: 0;
  overflow: hidden;
  transition: $transition;
  @include media-down(md) {
    max-width: 100%;
  }
  z-index: 1000;
}

.button {
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  border: none;
  display: flex;
  align-items: center;
  @include media-down(xl) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media print {
    display: none;
  }
}

.iconContainer {
  border: none;
  padding: 0;
  display: flex;
  height: 50px;
  width: 50px;
  background-color: $color-light;
  border-radius: 100%;
  margin-left: 15px;
  transition: $transition;
  @include media-down(xl) {
    margin-left: 0;
    margin-top: 8px;
  }
  :hover {
    opacity: 0.8;
  }
  // &_display {
  //   background-color: $color-variant-3-light;
  // }
}

.icon {
  padding: 7px;
  font-size: 35px;
  color: $color-secondary;
}
