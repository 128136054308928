@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  @include media-down(md) {
    row-gap: 24px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0px;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: $color-variant-6;
    @include media-down(md) {
      left: 20px;
    }
  }
  @media print {
    display: block;
  }
}
.date {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 32px;

  $margin-date-value: 10%;
  $margin-date-description: 15%;

  &::after {
    position: absolute;
    z-index: 50;
    content: "";
    height: 40px;
    width: 6px;
    border-radius: 20px;
    top: 4px;
    left: 0;
    transform: translateX(50%);
  }

  &:nth-of-type(odd) {
    &::after {
      background-color: $color-secondary;
    }
  }
  &:nth-of-type(even) {
    &::after {
      background-color: $color-primary;
    }
  }

  @include media-up(md) {
    width: 50%;
    padding-left: 0;
    &:nth-of-type(odd) {
      align-items: flex-end;
      text-align: end;
      .dateValue {
        padding-right: $margin-date-value;
      }
      .dateDescription {
        padding-right: $margin-date-description;
      }
      &::after {
        left: unset;
        right: 0;
        transform: translateX(50%);
      }
    }
    &:nth-of-type(even) {
      align-self: flex-end;
      .dateValue {
        padding-left: $margin-date-value;
      }
      .dateDescription {
        padding-left: $margin-date-description;
      }
      &::after {
        left: unset;
        left: 0;
        transform: translateX(-50%);
      }
    }
  }

  @media print {
    margin-bottom: 32px;
    display: block;
    page-break-inside: avoid;
  }
}
.dateValue {
  box-sizing: border-box;
  margin-bottom: 20px;
  @include media-down(md) {
    margin-bottom: 12px;
  }
}
.dateDescription {
  box-sizing: border-box;
  width: 80%;
  @include media-down(xl) {
    width: 100%;
  }
  @media print {
    display: block;
    margin-top: 8px;
  }
}
