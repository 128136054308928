@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 80px;
  justify-content: center;
  @include media-down(xxl) {
    row-gap: 112px;
  }
  @media print {
    display: block;
  }
}
.tile {
  position: relative;
  width: 372px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  text-align: center;
  align-items: center;
  &::after {
    position: absolute;
    content: "";
    width: 50%;
    top: 64px;
    right: calc(0px - 15px - 25%);
    height: 2px;
    background: $color-variant-6-light;
  }

  &:nth-of-type(3n),
  &:last-of-type {
    &::after {
      display: none;
    }
  }

  @include media-down(xxxl) {
    &:nth-of-type(odd) {
      &::after {
        display: block;
      }
    }
    &:nth-of-type(even),
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
  @include media-down(xxl) {
    &:not(:last-of-type) {
      &::after {
        content: " ";
        display: block;
        height: 50px;
        width: 2px;
        top: calc(100% + 56px);
        right: unset;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media print {
    display: block;
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
    page-break-inside: avoid;
    &::after {
      display: none !important;
    }
  }
}
.image {
  @media print {
    margin-bottom: 20px;
  }
}
.description {
  flex-grow: 1;
  @media print {
    margin-top: 20px;
    display: block;
  }
}
