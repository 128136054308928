@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 72px;
  @include media-down(xl) {
    row-gap: 40px;
  }

  @media print {
    display: block;
  }
}

.title {
  align-self: flex-start;
  position: relative;
  left: 9.5%;
  @include media-down(xxxl) {
    left: 6%;
  }
  @include media-down(xxl) {
    left: 7%;
  }
  @include media-down(xl) {
    left: 0;
    align-self: center;
  }
}

.subContainer {
  display: flex;
  flex-direction: column;
  @include media-down(xl) {
    row-gap: 24px;
  }
  @media print {
    display: block;
  }
}

.product {
  display: grid;
  grid-template-columns: 40% 1px 60%;
  align-items: center;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @include media-down(xxl) {
    grid-template-columns: 1fr 1px 1fr;
  }
  @include media-down(xl) {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
  }
  @media print {
    display: block;
    page-break-inside: avoid;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 0;
  width: 321px;
  @include media-up(xxxl) {
    padding: 0;
  }
  @include media-down(xxl) {
    padding: 28px 0;
  }
  @include media-down(xl) {
    padding: 0;
  }
  @media print {
    margin: auto;
  }
}

.separator {
  height: 100%;
  width: 3px;
  background-image: linear-gradient(
    to bottom,
    $color-primary 40%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: left;
  background-size: 3px 10px;
  background-repeat: repeat-y;
  @include media-down(xl) {
    height: 3px;
    width: 100%;
    background-image: linear-gradient(
      to right,
      $color-primary 40%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 10px 3px;
    background-repeat: repeat-x;
  }
  @media print {
    margin: 32px 0;
  }
}

.descriptionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 20px;
  border-radius: 30px;
  background-color: $color-variant-6-light;
  width: 66%;
  @include media-down(xl) {
    width: unset;
    margin-top: 32px;
  }
  @include media-down(lg) {
    border-radius: 20px;
  }
}

.descriptionWrapper::before {
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -10px;
  content: " ";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: $color-variant-6-light;
  @include media-down(xl) {
    left: 40px;
    top: 0;
  }
}

.button {
  width: fit-content;
  margin: auto;
  margin-top: 32px;
}
