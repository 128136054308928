@import "/styles/global/variables.scss";
@import "/styles/global/mixins.scss";

.color-base {
  fill: grey;
}

// Use variables defined in SectionMap, this is not a good practice
// because component are not isolated

.zone {
  transition: fill 0.15s;
  &:hover {
    @include media-up(sm) {
      fill: var(--map-color-hover);
    }
  }
}
.lvl1 {
  fill: var(--map-color-lvl-1);
}
.lvl2 {
  fill: var(--map-color-lvl-2);
}
.lvl3 {
  fill: var(--map-color-lvl-3);
}
.lvl4 {
  fill: var(--map-color-lvl-4);
}

.container {
  position: relative;
  page-break-inside: avoid;
}

.svg {
  @include media-down(lg) {
    width: 400px;
    height: 289px;
  }
  @include media-down(md) {
    width: 230px;
    height: 233px;
  }
  @media print {
    width: 400px;
    height: 289px;
  }
}

.tooltip {
  position: absolute;
  z-index: 2000;
  min-width: 200px;
  pointer-events: none;
  background: $color-dark;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 1px 2px 11px 2px rgb(0 0 0 / 27%);
  transform: translate(8px, 8px);
  border-radius: 8px;
  transition: opacity 0.1s;
  &_hidden {
    opacity: 0;
  }
  @include media-down(sm) {
    display: none;
  }
}
